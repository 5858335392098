/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/primeng.css";
@import "../node_modules/primeng/resources/themes/mdc-light-indigo/theme.css";
@import "../node_modules/bootstrap-icons/font/bootstrap-icons.min.css";
@import url("https://fonts.googleapis.com/css2?family=Asul:wght@400;700&family=Roboto+Slab:wght@100..900&display=swap");
@import "primeflex/primeflex.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";


* {
  font-family: "Asul", serif;
  color: black;

  ul {
    list-style: none;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.p-datatable>.p-datatable-wrapper {
  overflow: hidden;
}